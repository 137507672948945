import Animated from "animated/lib/targets/react-dom"
import { graphql, useStaticQuery } from "gatsby"
import flowRight from "lodash.flowright"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay, bindKeyboard } from "react-swipeable-views-utils"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const styles = {
  root: {
    padding: "0 30px",
  },
  slide: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    padding: `15px`,
    background: `transparent`,
  },
}

const Image = styled(BackgroundImage)`
  height: 400px;
  width: 400px;
  @media all and (max-width: 620px) {
    height: 270px;
    width: 270px;
  }
  @media all and (max-width: 320px) {
    height: 180px;
    width: 180px;
  }
  transform: scale3d(1.075, 1.075, 1.075) !important;
`

const AutoPlaySwipeView = flowRight(
  bindKeyboard,
  autoPlay
)(SwipeableViews)

export default () => {
  const {
    albums: { edges },
  } = useStaticQuery(query)
  const images = edges[0].node.images

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [position, setPosition] = React.useState(new Animated.Value(0))

  const handleSlideChange = index => {
    setActiveIndex(index)
    Animated.spring(position, { toValue: index }).start()
  }

  return (
    <AutoPlaySwipeView
      interval={3000}
      onChangeIndex={handleSlideChange}
      ignoreNativeScroll={true}
      style={styles.root}
      index={activeIndex}
      resistance
    >
      {images.map((item, currentIndex) => {
        const inputRange = images.map((_, i) => i)
        const scale = position.interpolate({
          inputRange,
          outputRange: inputRange.map(i => {
            return currentIndex === i ? 1 : 0.7
          }),
        })
        const opacity = position.interpolate({
          inputRange,
          outputRange: inputRange.map(i => {
            return currentIndex === i ? 1 : 0.3
          }),
        })
        const translateX = position.interpolate({
          inputRange,
          outputRange: inputRange.map(i => {
            return (100 / 3) * (i - currentIndex)
          }),
        })

        return (
          <Animated.div
            key={String(currentIndex)}
            style={{
              ...{
                opacity,
                transform: [{ scale }, { translateX }],
              },
              ...styles.slide,
            }}
          >
            <div style={{overflow: 'hidden'}}>
              <Image
                Tag="div"
                className={"bg-image"}
                fluid={item.fluid}
                backgroundColor={`#040e18`}
              />
            </div>
          </Animated.div>
        )
      })}
    </AutoPlaySwipeView>
  )
}

const query = graphql`
  query Art {
    albums: allContentfulAlbum(filter: { name: { eq: "art" } }) {
      edges {
        node {
          images {
            fluid(maxWidth: 450) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`
