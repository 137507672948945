import { graphql } from "gatsby"
import React from "react"
import { Heading, Text } from "rebass"
import { Player } from "video-react"
import Carousel from "../components/carousel"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { publicURL } = data.allFile.edges[0].node

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About Sigal" />
      <Carousel />
      <Heading p={[3, 4]} fontSize={[4, 5]} color="warning">
        Biography
      </Heading>
      <Text p={[3, 4]} fontSize={[2, 3]}>
        Sigal was born in 1967 in Israel.
        <br />
        <br />
        At the age of eighteen, she began to paint and later studied painting at
        the Academy of Arts. She decided to set up a home in Amsterdam. In the
        early nineties, she ran the gallery of Herman Brood in downtown Amsterdam
        and there it is where it all really began. The access was very easy.
        <br />
        <br />
        After several years of traveling around the world, every place was
        unique and gave great inspiration and strength. Today, painting is her
        whole world. Painting became her field that describes the work on the
        surface with paint, creating lines and stains, using different
        techniques with elements from nature such as sand or leaves. It can
        create the illusion of movement, volume, and depth; through painting, she
        expresses feelings, ideas, fantasies, and worldview.
      </Text>
      <Player autoPlay muted playsInline>
        <source src={publicURL} />
      </Player>
    </Layout>
  )
}

export const indexPageQuery = graphql`
  query Index {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { name: { eq: "sigal_Madmony_in_action" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
